.notExpanded {
  height: 40px !important;
  overflow: hidden;
  align-self: flex-end;
  cursor: pointer;
}

.notExpanded .three-line-legend-dark {
  position: absolute;
  height: 40px !important;
  top: -18px;
  align-items: center;
}
.notExpanded .tv-lightweight-charts {
  height: 40px !important;
  display: none;
}
.expanded {
  background-color: #fff !important;
}
.expanded .recharts-responsive-container {
  display: flex;
  margin-top: 40px;
}
