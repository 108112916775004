.three-line-legend-dark {
	width: 100%;
	height: 70px;
	position: absolute;
	padding: 8px;
	font-size: 12px;
	color: black;
	background-color: rgba(255, 255, 255, 0.23);
	text-align: left;
	z-index: 10;
  pointer-events: none;
	display: flex;
	justify-content: space-between !important;
	align-items: flex-start;
	top: -44px ;
}
@media screen and (max-width: 500px) {
	.three-line-legend-dark {
		top: -24px;
	}
}